<template>
  <div id="app">
    <!-- Use AppLayout to wrap the router-view -->
    <AppLayout :isLoggedIn="isLoggedIn">
      <router-view />
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue';

export default {
  name: 'App',
  components: {
    AppLayout,
  },
  data() {
    return {
      isLoggedIn: false, // Use consistent naming for the authentication status
    };
  },
  methods: {
    handleLoginSuccess() {
      this.isLoggedIn = true; // Update the authentication status
    }
  },
  computed: {
    isMainPage() {
      return this.$route.name === 'main'; // Ensure this matches the route name of your main page
    },
  },
};
</script>

<style>
#app {
  padding-bottom: 20px;
}

.main-container {
  max-width: 800px; /* Adjust this value as needed */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add some padding for spacing */
  /* Add any other global styles you want to apply across pages */
}
</style>