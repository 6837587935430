<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <router-link to="/" class="navbar-brand">
        <img src="../assets/ctms-logo-l.png" alt="CTMS Logo" class="logo">
      </router-link>

        <!-- "navbar-toggler" button for mobile devices -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleMenu"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navigation Links -->
        <div class="collapse navbar-collapse" :class="{ 'show': isMenuOpen }" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link to="/offices" class="nav-link">Offices</router-link>
            </li>
            <li class="nav-item" v-if="!isAuthenticated">
              <router-link to="/login" class="nav-link">Login</router-link>
            </li>
            <li class="nav-item" v-else>
              <router-link to="/profile" class="nav-link">Profile</router-link>
            </li>
            <li class="nav-item" v-if="isAuthenticated">
              <button @click="logout" class="nav-link btn btn-link">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import authService from '@/authService';

export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    isAuthenticated() {
      return authService.isAuthenticated();
    },
  },
  methods: {
    async logout() {
      authService.logout();
      localStorage.removeItem('user-token'); // Remove user-token from localStorage
      this.$router.push('/');
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
.logo {
  max-width: 100px; /* Set the maximum width for your logo */
  width: 100%; /* Ensure the logo takes the full available width */
  height: auto; /* This ensures the aspect ratio is maintained */
  /* Add any other CSS styles you want to apply */
}
/* Add your header styles here */
</style>