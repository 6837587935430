<template>
  <div class="container main-container">
    <h2>Register</h2>
    <!-- Display a success message when registration is successful -->
    <div v-if="registrationSuccess" class="alert alert-success" role="alert">
      Registration successful! You can now <router-link to="/login">log in</router-link>.
    </div>
       <!-- Registration Form -->
    <form @submit.prevent="registerUser">
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" class="form-control" id="username" v-model="user.username" required>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" v-model="user.email" required>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" v-model="user.password" required>
      </div>
      <!-- Additional fields as needed -->
      <div class="form-footer">
        <button v-demo-mode class="btn btn-primary">Register</button>
        <router-link to="/login" class="login-link">Already have an account?</router-link>
      </div>
    </form>
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegisterUser',
  data() {
    return {
      user: {
        username: '',
        email: '',
        password: ''
      },
      registrationSuccess: false, // To track registration success
      errorMessage: '',
    };
  },
  methods: {
  async registerUser() {
      this.errorMessage = ''; // Reset error message
      if (!this.user.username || !this.user.email || !this.user.password) {
        this.errorMessage = 'All fields are required.';
        return;
      }
      
      try {
        const response = await axios.post('/api/register', this.user);
        console.log('User registered:', response.data);
        this.registrationSuccess = true;
      
        // Redirect to profile page after a successful registration
        this.$router.push('/profile');

      } catch (error) {
        console.error('Error registering user:', error);
        this.registrationSuccess = false;

        // Set the error message from the response if available, otherwise set a default message
        this.errorMessage = error.response && error.response.data && error.response.data.message 
                            ? error.response.data.message 
                            : 'Registration failed. Please try again later.';
      }
    }
  }
}
</script>

<style scoped>

.form-footer {
  display: flex;
  justify-content: space-between; /* Aligns button to left, link to right */
  align-items: center;
  padding-top: 15px; /* Adds some space above the button */
}
.login-link {
  white-space: nowrap;
  color: #007bff; /* Bootstrap primary color for consistency */
  text-decoration: underline; /* Underline for emphasis */
}
/* Add your styles here */
</style>