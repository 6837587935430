<template>
  <div class="container main-container">
    <h2>Your Profile:</h2>

    <!-- User Profile Section -->
    <div class="user-profile-section">
    <!-- Display mode -->
    <div v-if="!isEditing">
      <p><strong>Username:</strong> {{ user.username }}</p>
      <p><strong>Email:</strong> {{ user.email }}</p>
      <p><strong>Full Name:</strong> {{ user.fullName }}</p>
      <p><strong>Project Admin ID:</strong> {{ user.projectId }}</p>
      <p><strong>Office Admin ID:</strong> {{ user.officeId }}</p>
      <button v-demo-mode class="btn btn-primary">Edit</button>
    </div>

    <!-- Edit mode -->
    <template v-if="isEditing">
      <div class="form-group">
        <label for="username">Username</label>
        <div class="input-group">
          <input type="text" class="form-control" id="username" v-model="user.username">
          <button class="btn btn-outline-secondary" @click="checkUsernameAvailability">Check Availability</button>
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" v-model="user.email">
      </div>

      <div class="form-group">
        <label for="fullName">Full Name</label>
        <input type="text" class="form-control" id="fullName" v-model="user.fullName">
      </div>

      <!-- Add a field for password (assuming you want to allow the user to change their password) -->
      <div class="form-group">
        <label for="password">New Password</label>
        <input type="password" class="form-control" id="password" v-model="user.newPassword">
      </div>

      <button @click="updateUser" class="btn btn-success">Save</button>
      <button @click="cancelEditing" class="btn btn-secondary">Cancel</button>
    </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authService from '@/authService';
import {jwtDecode} from 'jwt-decode';


export default {
  name: 'UserProfile',
  data() {
    return {
      user: {
        username: '', // Initialize with empty values
        email: '',
        fullName: '',
      },
      isEditing: false,
    };
  },

  async created() {
    // Fetch user data when the component is created
    console.log('Attempting to fetch user data...');
    await this.fetchUserData();
  },

  methods: {
    async fetchUserData() {
      try {
        console.log('Fetching user data...');
        const token = authService.getToken(); // Get the token using authService.getToken()
        const decoded = jwtDecode(token); // Decode the token
        console.log('Token found:', decoded);
        const userId = decoded.userId; // Extract the userId from the decoded token
        console.log('User ID:', userId);

        const response = await axios.get(`/api/users/profile/`); // Updated API call without specifying the userId
        console.log('API call made to /api/users/profile/', response);

        if (response.status === 200) {
          console.log('User data retrieved:', response.data);
          this.user = { ...response.data };
        } else {
          console.error('Failed to fetch user data, status:', response.status);
        }
      } catch (error) {
        console.error('Error during API call to /api/users/profile/', error.response || error.message);
      }
    },

    async updateUser() {
      try {
        // Send updated user data to your backend for processing
        const response = await axios.put('/api/users/settings', this.user); // Replace with your user API route
        if (response.status === 200) {
          console.log('User updated:', response.data);
          // Handle post-update logic
          this.isEditing = false; // Turn off editing mode after successful update
        } else {
          console.error('Error updating user:', response.statusText);
        }
      } catch (error) {
        console.error('Error updating user:', error);
        // Handle update error
      }
    },
    async checkUsernameAvailability() {
      try {
        const response = await axios.get('/api/users/check-username', { params: { username: this.user.username } });
        if (response.data.available) {
          console.log('Username is available');
          // Handle available username case
        } else {
          console.log('Username is not available');
          // Handle unavailable username case
        }
      } catch (error) {
        console.error('Error checking username availability:', error);
      }
    },

    startEditing() {
      // Enable editing mode
      this.isEditing = true;
    },
    cancelEditing() {
      this.isEditing = false;
      // Optionally reset the edited fields to their original values
      this.fetchUserData(); // Assuming this method resets user data to the original
    },
  },

  mounted() {
    authService.handleTokenExpiry();
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
.input-group {
  display: flex;
  margin-bottom: 1rem;
}
.input-group > input {
  flex-grow: 1;
  margin-right: 0.5rem;
}
.container.main-container {
  padding-bottom: 20px; /* Add padding to the bottom of the container */
}

.user-profile-section {
  border: 1px solid #ccc; /* Add a border around the sections */
  padding: 10px; /* Add padding inside the sections */
  margin-bottom: 20px; /* Add margin to separate the sections */
  transition: all 0.3s; /* Add transition for smooth expansion */
}
/* Add your styles here */
</style>