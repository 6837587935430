<template>
  <div class="container main-container">
    <router-link :to="`/offices/${officeSlug}/projects/${projectSlug}`" class="back-link">Back to Project</router-link>

    <div class="banner-container">
      <img :src="officeDetails.banner_url || require('@/assets/default-banner.jpg')" alt="Office Banner">
    </div>

    <div class="content-container">
    <h2>Log Details:</h2>

   <!-- Loading Icon -->
   <div class="loading-container" v-if="isLoading">
      <loading-icon style="position: absolute; top: 0; left: 50%; transform: translateX(-50%);"></loading-icon>
  </div>

   <div v-else>

    <div class="log-entries">
      <div v-for="log in logs" :key="log.log_id" class="log-entry">
        <p><strong>Timestamp:</strong> {{ new Date(log.timestamp).toLocaleString() }}</p>
        <p><strong>Action:</strong> {{ log.action_type }}</p>
        <p><strong>Entity:</strong> {{ log.entity_type }} (ID: {{ log.entity_id }})</p>
        <p><strong>User:</strong> {{ log.user_id }}</p>
        <p><strong>Details:</strong> {{ log.details }}</p>
      </div>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      logs: [],
      projectSlug: null,
      officeSlug: null,
      officeDetails: {}
    };
  },
  methods: {
    async fetchLogs() {
      try {
        const response = await axios.get(`/api/logs/projects/${this.projectSlug}`);
        console.log('Logs fetched:', response.data); 
        this.logs = response.data;
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    },

    async fetchOfficeDetails() {
      try {
        const response = await axios.get(`/api/offices/${this.officeSlug}`);
        console.log('Office details fetched:', response.data);
        this.officeDetails = response.data;
      } catch (error) {
        console.error('Error fetching office details:', error);
      }
    }
  },
  created() {
    this.projectSlug = this.$route.params.projectSlug;
    this.officeSlug = this.$route.params.officeSlug;
    console.log(`Component created with project slug: ${this.projectSlug} and office slug: ${this.officeSlug}`);
    this.fetchLogs();
    this.fetchOfficeDetails();
  }
};
</script>

<style scoped>

.banner-container {
  width: 800px; /* Set the fixed width for the banner container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add padding for spacing around the content */
}

.banner-container img {
  max-width: 100%; /* Ensure the banner image doesn't exceed its container width */
  height:auto;
}
.content-container {
  max-width: 100%; /* Ensure it doesn't exceed the width of the banner container */
  margin: 20px auto; /* Add margin between the title and the banner */
  padding: 0 20px; /* Optional padding for content */
  box-sizing: border-box; /* Ensure padding doesn't increase the width */
}
.back-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.back-link::before {
  content: '← ';
  font-size: 1.2em;
}

.log-entry {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.log-entry p {
  margin: 5px 0;
}

.log-entry strong {
  font-weight: bold;
}
</style>

