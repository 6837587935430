import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router'; 
  
const app = createApp(App);

app.directive('demo-mode', {
    mounted(el) {
      console.log("Directive bound to element", el); // Log to see if the directive is binding
      const popup = document.createElement('div');
      popup.innerHTML = 'Demo version - action disabled :)';
      popup.style.position = 'absolute';
      popup.style.backgroundColor = 'white';
      popup.style.border = '1px solid black';
      popup.style.padding = '5px';
      popup.style.borderRadius = '5px';
      popup.style.display = 'none';
      popup.style.zIndex = 1000; // Ensures the popup is above other elements
  
      document.body.appendChild(popup);
  
      el.addEventListener('click', (e) => {
        console.log("Clicked element with v-demo-mode", el); // Log when element is clicked
        e.preventDefault(); // Prevent default action
  
        // Position the popup and show it
        const rect = el.getBoundingClientRect();
        popup.style.left = `${rect.left + window.scrollX}px`;
        popup.style.top = `${rect.bottom + window.scrollY + 5}px`; // 5px below the element
        popup.style.display = 'block';
  
        // Hide the popup after 3 seconds
        setTimeout(() => {
          popup.style.display = 'none';
        }, 3000);
      });
    },
    unmounted(el) {
      // Clean up if the element is removed
      el.removeEventListener('click');
    }
  });

  
app.use(router).mount('#app');