<template>
  <div class="container main-container">
   <div v-if="projectDetails && officeDetails">

    <router-link :to="`/offices/${officeSlug}`" class="back-link" style="margin-bottom: 10px;">Back to Office</router-link>

    <div class="banner-container">
      <img :src="officeDetails.banner_url || require('@/assets/default-banner.jpg')" alt="Office Banner">
    </div>

  <div class="content-container">
    <div class="header-container">
      <h1>{{ projectDetails.project_name }}</h1>
      
      <div class="balance-container">
        Project Balance: {{ projectBalance.toFixed(2) }}
      </div>
    </div>
    <h2>Details:</h2>
    <ul>
      <li><strong>Start Date:</strong> {{ formatDate(projectDetails.start_date) }}</li>
      <li v-if="projectDetails.end_date"><strong>End Date:</strong> {{ formatDate(projectDetails.end_date) }}</li>
      <li><strong>Office:</strong> {{ officeDetails.office_name }}</li>
      <li><strong>Description:</strong> {{ projectDetails.description }}</li>
      <li><strong>Funding Round:</strong> {{ projectDetails.funding_round }}</li>
      <li><strong>Project Admin ID:</strong> {{ projectDetails.project_admin_id }}</li>
      <!-- Add more project details here -->
    </ul>

    <h2>Add Treasury Item:</h2>
    <button @click="toggleTreasuryForm">Add Treasury Item</button>
    <div v-if="showTreasuryForm">
      <!-- Form for adding a new treasury item -->
      <form @submit.prevent="addTreasuryItem" class="form-inline">
        <!-- Form fields bound to newTreasuryItem properties -->
        <div class="form-group">
          <input id="line-item" v-model="newTreasuryItem.line_item" type="text" placeholder="Line Item" class="form-control">
        </div>
        <div class="form-group">
          <input id="amount" v-model="newTreasuryItem.amount" type="number" placeholder="Amount" class="form-control">
        </div>
        <div class="form-group">
          <select id="category" v-model="newTreasuryItem.category_id" class="form-control">
            <option disabled value="">Please select a category</option>
            <option v-for="category in categories" :key="category.category_id" :value="category.category_id">
              {{ category.category_name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <input id="transaction-date" v-model="newTreasuryItem.transaction_date" type="date" placeholder="Transaction Date" class="form-control">
        </div>
        <!-- Assuming project_id is set elsewhere in your component -->
        <!-- Add other fields as necessary -->
        <button type="submit" v-demo-mode>Submit</button>
      </form>
      <p v-if="successMessage">{{ successMessage }}</p>
    </div>

    <div class="treasury-section">

      <h2>Treasury Table:</h2>

      <table>
        <!-- Table headers ... -->
        <thead>
          <tr>
            <th>Line Item</th>
            <th @click="sortColumn('amount')">
              Amount
              <span v-if="sortState.amount === 'asc'">▲</span>
              <span v-else-if="sortState.amount === 'desc'">▼</span>
              <span v-else>↕</span>
            </th>
            <th>Category</th>
            <th @click="sortColumn('date')">
              Transaction Date
              <span v-if="sortState.date === 'asc'">▲</span>
              <span v-else-if="sortState.date === 'desc'">▼</span>
              <span v-else>↕</span>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in projectTreasury" :key="item.treasury_id" @mouseover="hover = item.treasury_id" @mouseleave="hover = null">
            <!-- Line Item -->
            <td>
              <span v-if="editItemId !== item.treasury_id">{{ item.line_item }}</span>
              <input v-else v-model="editableItem.line_item" type="text">
            </td>
            <!-- Amount -->
            <td>
              <span v-if="editItemId !== item.treasury_id">{{ item.amount }}</span>
              <input v-else v-model="editableItem.amount" type="number">
            </td>
            <!-- Category -->
            <td>
              <span v-if="editItemId !== item.treasury_id">{{ getCategoryName(item.category_id) }}</span>
              <!-- Category Select for editing (if needed) -->
            </td>
            <!-- Transaction Date -->
            <td>
              <span v-if="editItemId !== item.treasury_id">{{ formatDate(item.transaction_date) }}</span>
              <!-- Transaction Date Input -->
            </td>

            <!-- Actions -->
            <td>
              <div class="action-container">
                <span v-if="editItemId !== item.treasury_id">
                  <span
                    class="action-text"
                    @click="startEditing(item)"
                    @mouseover="hover = item.treasury_id"
                    @mouseleave="hover = null"
                    v-demo-mode
                  >
                    Edit
                  </span>
                  <span v-if="hover === item.treasury_id">
                  / <!-- Render '/' only when hovering over the row -->
                 </span>
                  <span
                    class="action-text"
                    @click="confirmTreasuryItemDeletion(item.treasury_id)"
                    @mouseover="hover = item.treasury_id"
                    @mouseleave="hover = null"
                    v-demo-mode
                  >
                    Delete
                  </span>
                </span>
                <span v-else>
                  <button @click="saveEdits(item.treasury_id)">Save</button>
                  <button @click="cancelEdits(item)">Cancel</button> <!-- Pass item to cancelEdits method -->
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


<span @click="navigateToLogs" class="log-text">View Project Logs</span>

<span @click="confirmProjectDeletion" class="delete-text" v-demo-mode>Delete Project</span>
</div>
</div>

<div v-else>
    <!-- Loading or no data available -->
    <p>Loading project details...</p>
  </div>
  
</div>
</template>

<style>
.banner-container {
  width: 800px; /* Set the fixed width for the banner container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add padding for spacing around the content */
}

.banner-container img {
  max-width: 100%; /* Ensure the banner image doesn't exceed its container width */
  height:auto;
}
.content-container {
  max-width: 100%; /* Ensure it doesn't exceed the width of the banner container */
  margin: 20px auto; /* Add margin between the title and the banner */
  padding: 0 20px; /* Optional padding for content */
  box-sizing: border-box; /* Ensure padding doesn't increase the width */
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-container {
  background-color: #f0f0f0;
  margin-left: auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  .category-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Space between tabs and table */
}
.category-tabs button {
  margin-right: 5px;
  /* Style your tabs/buttons */
}

.active-tab {
  font-weight: bold;
  color: blue;
  /* Style for the active tab */
}

.delete-text {
  color: darkred;
  cursor: pointer;
  text-decoration: underline;
  float: right;
}

.delete-text:hover {
  color: red;
}

.action-container {
  display: flex;
  align-items: center;
}

.action-text {
  visibility: hidden; /* Initially invisible but still takes up space */
  opacity: 0;
  transition: opacity 0.3s, visibility 0s 0.3s; /* Transition for opacity and delay hiding */
  cursor: pointer;
  text-decoration: underline;
  color: grey;
  text-align: center;
}

tr:hover .action-text {
  visibility: visible; /* Make text visible */
  opacity: 1;
  transition-delay: 0s; /* Remove delay when showing */
}
.action-text:hover {
  color: rgb(56, 56, 87);
}

/* Ensure that action text is in the center of the td */
.treasury-section td .action-container {
  justify-content: center; /* Center the flex container */
}

.treasury-section {
  margin: 20px 0;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ccc; /* Add a border to the section */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  width: 100%;
  overflow-x: auto;
}
/* Center the treasury table */
.treasury-section table {
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0px;
  box-sizing: border-box;
  width: 100%;
  table-layout: fixed;
}

.treasury-section th, .treasury-section td {
  padding: 10px; /* Add padding to table cells for spacing */
  text-align: center; /* Center-align table content */
  white-space: nowrap; 
  box-sizing: border-box;
  transition: background-color 0.3s; 
}

.treasury-section th {
  background-color: #f0f0f0; /* Add a background color to table headers */
  font-weight: bold; /* Make headers bold for emphasis */
}

/* Enhance table row hover effect */
.treasury-section table tr:hover {
  background-color: #f5f5f5; /* Change the background color on hover */
}

.log-text {
  color: darkgreen;          /* Green text */
  text-decoration: underline; /* Underlined text */
  background-color: transparent; /* Transparent background */
  border: none;          /* No border */
  cursor: pointer;;
}
.log-text:hover {
  color: green;
}

.back-link {
  color: #007bff; /* Blue color for the link */
  text-decoration: none; /* No underline */
  cursor: pointer; /* Cursor changes to pointer on hover */
  font-weight: 500; /* Slightly bold text */
}

.back-link::before {
  content: '← '; /* Unicode left arrow */
  font-size: 1.2em; /* Slightly larger arrow */
}
.action-text {
  opacity: 0;         /* Initially invisible */
  cursor: pointer;    /* Change cursor to pointer on hover */
  transition: opacity 0.3s; /* Smooth transition for opacity change */
}
.action-text:hover {
  opacity: 1;         /* Make text visible on hover */
  text-decoration: none; 
}
th {
  cursor: pointer;
}
th span {
  margin-left: 5px;
}

.form-inline {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start of the flex container */
  gap: 10px; /* Adds space between form elements */
  width: 100%; /* Adjust width as needed */
  max-width: 600px; /* Maximum width */
  margin: 0 auto; /* Center the form */
}

.form-inline .form-group {
  width: 100%; /* Full width for each form group */
}

.form-inline button[type="submit"] {
  background-color: #28a745; /* Green background */
  color: white;
  border: none;
  border-radius: 5px; /* Rounded edges */
  padding: 10px 15px;
  cursor: pointer;
}

.form-inline button[type="submit"]:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .form-inline .form-control {
    width: 100%; /* Auto width for larger screens */
  }
  .treasury-section th, .treasury-section td {
    padding: 5px; /* Reduce padding on smaller screens */
    font-size: 0.9em; /* Reduce font size if needed */
  }
}
  /* Add more styling as needed */
</style>

<script>
import axios from 'axios';

//import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'ProjectProfile',
  data() {
    return {
      projectDetails: {
        project_id: null,
        project_name: '',
        project_admin_id: null,
        funding_round: null,
        is_active: true,
        status: '',
        description: '',
        start_date: null,
        end_date: null,
        slug: '',
        office_id: null,
        officeSlug: '',
        currentCategory: null, // Initially, no category is selected
        categories: [], // Array to store categories
        treasuryItems: [], // Array of treasury items
        hover: null, // ID of the item currently hovered over
        editItemId: null, // ID of the item currently being edited
        editableItem: {}, // Object holding the editable fields of the item being edited
      },
      
      officeDetails: null,

      newTreasuryItem: {
        project_id: null,   // To associate the treasury item with a project
        line_item: '',      // Description of the item
        amount: 0,
        category_id: null,  // If you categorize treasury items
        transaction_date: '', // Date of transaction
        // Additional fields as per your treasury table
      },

      projectTreasury: [], // Initialize projectTreasury array
      categories: [], // Initialize categories array
      showTreasuryForm: false, // Controls the visibility of the form
      successMessage: '',

      sortState: {
      amount: null, // 'asc', 'desc', or null
      date: null,
    },
      // ... any other data properties needed for the component
    };
  },
  methods: {
    setCurrentCategory(categoryId) {
      this.currentCategory = categoryId;
      console.log('Method - setCurrentCategory called, currentCategory:', this.currentCategory);
    },
    async fetchProjectDetails(projectSlug) {
      if (!projectSlug) {
        console.error('Project slug is undefined');
        return;
      }
      try {
        const response = await axios.get(`/api/projects/slug/${projectSlug}`);
        this.projectDetails = response.data;
        console.log('Project details fetched:', this.projectDetails);
        // Pass office_id to fetchOfficeDetails
        if (this.projectDetails.office_id) {
          console.log('Fetching office details...');
          await this.fetchOfficeDetails(this.projectDetails.office_id);
        }
        // Automatically set the project_id for new treasury items
        this.newTreasuryItem.project_id = this.projectDetails.project_id;

        if (!this.projectDetails.project_id) {
          console.error('Project ID not found in project details');
          return;
        }
        await this.fetchProjectTreasury();
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    },
    async fetchOfficeDetails(officeId) {
      try {
        const response = await axios.get(`/api/offices/${officeId}`);
        this.officeDetails = response.data;
        console.log('Office details fetched:', this.officeDetails);
      } catch (error) {
        console.error('Error fetching office details:', error);
      }
    },
   toggleTreasuryForm() {
        this.showTreasuryForm = !this.showTreasuryForm;
        if (this.showTreasuryForm) this.successMessage = ''; // Reset success message when opening the form
      },
      async addTreasuryItem() {
        if (!this.newTreasuryItem.project_id) {
          console.error('Project ID is not set for the new treasury item');
          return; // Prevent submission or handle this case as needed
        }

        if (!this.newTreasuryItem.line_item.trim() || !this.newTreasuryItem.amount) {
          alert('Line item and amount are required');
          return;
        }

        // Check if the selected category is an income category (category_type is 'Income')
        const selectedCategory = this.categories.find(category => category.category_id === this.newTreasuryItem.category_id);

        if (selectedCategory) {
          if (selectedCategory.category_type === 'Income' && this.newTreasuryItem.amount < 0) {
            // Display an error message if it's an income category and the amount is negative
            this.errorMessage = 'Income categories should have positive amounts.';
            return; // Prevent further processing
          }

          if (selectedCategory.category_type === 'Expense' && this.newTreasuryItem.amount > 0) {
            // Display an error message if it's an expense category and the amount is positive
            this.errorMessage = 'Expense categories should have negative amounts.';
            return; // Prevent further processing
          }
        } else {
          // Handle case where the selected category is not found (optional)
          console.error('Selected category not found');
          return;
        }

        try {
          console.log('Adding treasury item:', this.newTreasuryItem);
          const response = await axios.post('/api/treasury', this.newTreasuryItem);
          console.log('Treasury item added:', response.data);

          // Handle success
          this.successMessage = 'Treasury item added successfully!';
          this.resetTreasuryForm();
        } catch (error) {
          console.error('Error adding treasury item:', error);
          this.successMessage = ''; // Clear success message on error
        }
      },

       // Fetch project treasury data using project_id
    async fetchProjectTreasury() {
      const projectId = this.projectDetails.project_id; // Get project ID from projectDetails
      console.log(`Fetching treasury details for project_id: ${projectId}`);
      if (!projectId) {
        console.error('Project ID is undefined');
        return;
      }

      try {
        const response = await axios.get(`/api/treasury/projects/${projectId}`);
        console.log('API response:', response);
        this.projectTreasury = response.data;
        console.log('Assigned treasury data:', this.projectTreasury);
      } catch (error) {
        console.error('Error fetching project treasury:', error);
      }

      console.log('Method - fetchProjectTreasury called, response:', this.projectTreasury);
   },
   resetTreasuryForm() {
    this.newTreasuryItem = {
      line_item: '',
      amount: 0,
      category_id: null,
      transaction_date: '',
      project_id: this.newTreasuryItem.project_id, // Keep the project_id if it should stay the same
    };
  },
    // Fetch categories data
    async fetchCategories() {
      try {
        const response = await axios.get(`/api/treasury/categories`);
        this.categories = response.data;
        console.log('Fetched categories:', this.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },

    confirmProjectDeletion() {
      if (window.confirm("Are you sure? Deletion cannot be restored once submitted.")) {
        this.deleteProject();
      }
    },
    async deleteProject() {
      try {
        // Replace with your actual delete endpoint
        await this.$axios.delete(`/api/projects/${this.projectDetails.project_id}`);
        // Redirect or update UI upon successful deletion
      } catch (error) {
        console.error('Error deleting project:', error);
        // Handle errors
      }
    },

     // Start editing a treasury item
     startEditing(item) {
      this.editItemId = item.treasury_id;
      this.editableItem = { ...item }; // Create a copy of the item for editing
    },

    // Save edits made to a treasury item
    async saveEdits() {
      try {
        // Replace with your actual API endpoint and payload structure
        await this.$axios.put(`/api/treasury/${this.editableItem.treasury_id}`, this.editableItem);
        // Update the item in the treasuryItems array with new edits
        const index = this.treasuryItems.findIndex(item => item.treasury_id === this.editableItem.treasury_id);
        if (index !== -1) {
          this.treasuryItems[index] = { ...this.editableItem };
        }
        // Reset editing state
        this.editItemId = null;
        this.editableItem = {};
      } catch (error) {
        console.error('Error saving edits:', error);
        // Handle error (e.g., show an error message)
      }
    },

    // Cancel editing and revert changes for a specific item
    cancelEdits(item) {
      this.editItemId = null;
      this.editableItem = {};
      // Set a unique key for the canceled item to force a re-render
      item.key = Date.now();
    },

    // Confirm and delete a treasury item
    confirmTreasuryItemDeletion(itemId) {
      if (window.confirm("Are you sure? Deletion cannot be restored once submitted.")) {
        this.deleteTreasuryItem(itemId);
      }
    },

    // Delete a treasury item
    async deleteTreasuryItem(itemId) {
      try {
        // Replace with your actual API endpoint
        await this.$axios.delete(`/api/treasury/${itemId}`);
        // Remove the item from the treasuryItems array
        this.treasuryItems = this.treasuryItems.filter(item => item.treasury_id !== itemId);
      } catch (error) {
        console.error('Error deleting treasury item:', error);
        // Handle error (e.g., show an error message)
      }
    },

    navigateToLogs() {
      const projectId = this.projectDetails.project_id;
      if (projectId) {
        this.$router.push({ name: 'LogView', params: { project_id: projectId } });
      } else {
        console.error('Project ID is missing');
      }
    },

    getCategoryName(categoryId) {
    const category = this.categories.find(cat => cat.category_id === categoryId);
    return category ? category.category_name : 'Unknown Category';
  },

  sortColumn(column) {
    // Toggle sort state
    if (this.sortState[column] === 'asc') {
      this.sortState[column] = 'desc';
    } else {
      this.sortState[column] = 'asc';
    }
    // Sorting logic
    if (column === 'amount') {
      this.projectTreasury.sort((a, b) => {
        return this.sortState[column] === 'asc' ? a.amount - b.amount : b.amount - a.amount;
      });
    } else if (column === 'date') {
      this.projectTreasury.sort((a, b) => {
        const dateA = new Date(a.transaction_date);
        const dateB = new Date(b.transaction_date);
        return this.sortState[column] === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }
  },

   // Function to format date
   formatDate(date) {
        if (!date) return '';
        const formattedDate = new Date(date).toLocaleDateString(); // You can customize the format
        return formattedDate;
    },

  },
  
  computed: {
    projectBalance() {
      return this.projectTreasury.reduce((total, item) => total + parseFloat(item.amount), 0);
    },
    /* filteredTreasury() {
      const filtered = this.currentCategory === null
      ? this.projectTreasury
      : this.projectTreasury.filter(item => item.category_id === this.currentCategory);
    
      console.log('Computed - filteredTreasury:', filtered);
    return filtered;
    },
    filteredCategories() {
      // Filter categories based on treasury data
      const treasuryCategoryIds = new Set(this.projectTreasury.map(item => item.category_id));
      const filtered = this.categories.filter(category => treasuryCategoryIds.has(category.category_id));
      console.log('Filtered categories:', filtered);
      return filtered;
    }, */
  },

  watch: {
  '$route': function() {
    this.$nextTick(() => {
      const newProjectSlug = this.$route.params.projectSlug;
      console.log('Route projectSlug after nextTick:', newProjectSlug);
      if (newProjectSlug) {
        this.fetchProjectDetails(newProjectSlug);
      }
    });
  },
  hover(newVal) {
    console.log('Hovering over:', newVal);
  }
},

created() {
  console.log('Component created');
  console.log('Initial route params:', this.$route.params);
  this.officeSlug = this.$route.params.officeSlug;
  this.fetchCategories();
  this.sortState = {
    amount: null,
    date: null,
  };
},

 async mounted() {
  await this.$nextTick();
  console.log('DOM updated in mounted');
  const projectSlug = this.$route.params.projectSlug;
  console.log('Project slug in mounted after nextTick:', projectSlug);
  if (projectSlug) {
    console.log('Component mounted, fetching project details...');
    await this.fetchProjectDetails(projectSlug);
    console.log('Project details fetched, fetching treasury data...');
    await this.fetchProjectTreasury();
    console.log('Treasury data fetched:', this.projectTreasury);
  }
  console.log('Mounted - Initial data fetching called');
  await this.fetchCategories(); // Fetch categories on component mount
  console.log('Mounted - Categories fetched:', this.categories); 
  }
}
</script>
