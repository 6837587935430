import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import OfficeList from '../views/OfficeList.vue';
import OfficeProfile from '../views/OfficeProfile.vue';
import ProjectProfile from '../views/ProjectProfile.vue';
import LogView from '../views/LogView.vue';
import UserProfile from '../views/UserProfile.vue';
import LoginUser from '../views/LoginUser.vue';
import RegisterUser from '../views/RegisterUser.vue';
import MainPage from '../views/MainPage.vue';
/* import CallBack from '@/components/CallBack.vue';
import { auth0Client } from '@/authClient'; */

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/offices',
    name: 'Offices',
    component: OfficeList,
  },
  {
    path: '/offices/:slug',
    name: 'OfficeProfile',
    component: OfficeProfile,
  },
  {
    path: '/offices/:officeSlug/projects/:projectSlug',
    name: 'ProjectProfile',
    component: ProjectProfile,
  },
  {
    path: '/offices/:officeSlug/projects/:projectSlug/logs/:project_id',
    name: 'LogView',
    component: LogView,
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {requiresAuth: true }
  },
  {
    path: '/login',
    name: 'LoginUser',
    component: LoginUser
  },
  {
    path: '/register',
    name: 'RegisterUser',
    component: RegisterUser
  },
/*   {
    path: '/callback',  // Add this for Auth0 callback
    name: 'CallBack',
    component: CallBack
  } */

  // Define other routes here
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('user-token'); // Use 'user-token' as the key

  const protectedRoutes = ['/profile']; // Define other protected routes as needed

  if (protectedRoutes.includes(to.path) && !isLoggedIn) {
    next('/login');
  } else {
    next(); // Continue if the route is not protected or the user is logged in
  }
});

export default router;