<template>
  <div class="main-container">

   <router-link to="/home" class="back-link">Back to Home</router-link>

   <div class="banner-container">
    <img :src="COMPANY_BANNER_URL || require('@/assets/default-banner.jpg')" alt="Office Banner">
  </div>

  <div class="content-container">
   <h2>Office List</h2>

   <!-- Loading Icon -->
   <div class="loading-container" v-if="isLoading">
      <loading-icon style="position: absolute; top: 0; left: 50%; transform: translateX(-50%);"></loading-icon>
  </div>

   <div v-else>
    <ul>
      <li v-for="office in offices" :key="office.office_id">
        <router-link :to="'/offices/' + office.slug">{{ office.office_name }}</router-link>
      </li>
    </ul>
    <button @click="toggleOfficeForm">Add New Office</button>
      <!-- Form for adding a new office -->
      <div v-if="showOfficeForm">
      <form @submit.prevent="addOffice">
      <input v-model="newOffice.office_name" type="text" placeholder="Office Name">
      <input v-model="newOffice.location" type="text" placeholder="Location">
      <input v-model="newOffice.logo_url" type="text" placeholder="Logo URL">
      <input v-model="newOffice.banner_url" type="text" placeholder="Banner URL">
      <!-- Add other fields as necessary -->
      <button v-demo-mode class="btn btn-success">Submit</button>
    </form>
     <!-- Success message display -->
    <p v-if="successMessage">{{ successMessage }}</p>
      <!-- Error message display -->
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
    </div>
  </div>
  </div>
</template>

<style>

.banner-container {
  width: 800px; /* Set the fixed width for the banner container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add padding for spacing around the content */
}

.banner-container img {
  max-width: 100%; /* Ensure the banner image doesn't exceed its container width */
  height:auto;
}

.content-container {
  max-width: 100%; /* Ensure it doesn't exceed the width of the banner container */
  margin: 20px auto; /* Add margin between the title and the banner */
  padding: 0 20px; /* Optional padding for content */
  box-sizing: border-box;
}

.error-message {
color: red;
/* Other styling for error messages */
}
.delete-button {
background-color: red;
color: white;
border: none;
padding: 10px 15px;
cursor: pointer;
}

.back-link {
color: #007bff; /* Blue color for the link */
text-decoration: none; /* No underline */
cursor: pointer; /* Cursor changes to pointer on hover */
font-weight: 500; /* Slightly bold text */
}

.back-link::before {
content: '← '; /* Unicode left arrow */
font-size: 1.2em; /* Slightly larger arrow */
}

</style>  
  
<script>
import LoadingIcon from '@/components/LoadingIcon.vue';
import axios from 'axios';

function isValidURL(url) {
// Regular expression for a simple URL validation
const urlPattern = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,6}\.?)(\/[\w.]*)*\/?(\?\S*)?(#\S*)?$/i;
return urlPattern.test(url);
}

export default {
    name: 'OfficeList',
    data() {
        return {
            offices: [],
            newOffice: {
                office_name: '',
                location: '',
                logo_url: '',
                banner_url: '', // URL for the office banner
            },
            showOfficeForm: false,
            successMessage: '',
            errorMessage: '', // For displaying error messages
            isLoading: true,
        };
    },
    methods: {
        toggleOfficeForm() {
            this.showOfficeForm = !this.showOfficeForm;
            if (this.showOfficeForm)
                this.successMessage = ''; // Reset success message when opening the form
        },
        async fetchOfficeData() {
          try {
            const response = await axios.get('/api/offices');
            console.log('API call made to /api/offices:', response);
            
            if (response.status === 200) {
              console.log('Offices response data:', response.data);
              this.offices = [...response.data];
              this.isLoading = false;
            } else {
              console.error('Failed to fetch office data, status:', response.status);
            }
          } catch (error) {
            console.error('Error during API call to /api/offices:', error.response || error.message);
          }
        },
        async addOffice() {
            if (!this.newOffice.office_name.trim()) {
                this.errorMessage = 'Office name cannot be empty.';
                return;
            }
            if (!isValidURL(this.newOffice.logo_url) || !isValidURL(this.newOffice.banner_url)) {
                // Show an error message or prevent form submission
                this.errorMessage = 'Invalid logo or banner URL';
                return;
            }
            try {
                const response = await axios.post('/api/offices', this.newOffice);
                console.log('Office added:', response.data);
                this.successMessage = 'Office added successfully!';
                this.resetForm();
            }
            catch (error) {
                console.error('Error adding office:', error);
                this.successMessage = ''; // Reset success message on error
            }
        },
        resetForm() {
            this.newOffice = { office_name: '', location: '' };
            this.errorMessage = '';
        },
        confirmOfficeDeletion() {
            if (window.confirm("Are you sure? Deletion cannot be restored once submitted.")) {
                this.deleteOffice();
            }
        },
        async deleteOffice() {
            try {
                // Replace with your actual delete endpoint
                await this.$axios.delete(`/api/offices/${this.officeDetails.office_id}`);
                // Redirect or update UI upon successful deletion
            }
            catch (error) {
                console.error('Error deleting office:', error);
                // Handle errors (show error message to the user)
            }
        }
    },
    mounted() {
        console.log("Component mounted. Fetching office list.");
        this.fetchOfficeData();
    },
    components: { 
      LoadingIcon 
    },
};
</script>