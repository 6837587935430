<template>
  <div class="container main-container">
   
    <router-link to="/offices" class="back-link">Back to Office List</router-link>
   
    <div class="banner-container">
      <img :src="officeDetails.banner_url || require('@/assets/default-banner.jpg')" alt="Office Banner">
    </div>

   <!-- Loading Icon -->
   <div class="loading-container" v-if="isLoading">
      <loading-icon style="position: absolute; top: 0; left: 50%; transform: translateX(-50%);"></loading-icon>
  </div>

   <div v-else>

    <div class="content-container">
    <h2>
      <img :src="officeDetails.logo_url || require('@/assets/default-logo.png')" alt="Office Logo" class="office-logo">
      {{ officeDetails.office_name }}
    </h2>
    <div>
      <p>Location: {{ officeDetails.location }}</p>
      <p>Description: {{ officeDetails.description || `This is office ${officeDetails.office_id}` }}</p>
      <h3>Projects:</h3>
      <ul>
        <li v-for="project in projects" :key="project.project_id">
         <router-link :to="'/offices/' + officeDetails.slug + '/projects/' + project.slug">{{ project.project_name }}</router-link>
       </li>
      </ul>
    </div>

    <button @click="toggleAddProjectForm" class="add-project-button">Add New Project</button>

    <div v-if="showAddProjectForm">
       <!-- Form for adding a new project -->
      <form @submit.prevent="submitProject">
        <div class="form-group">
          <label for="projectName">Project Name</label>
          <input type="text" class="form-control" id="projectName" v-model="newProject.name" required>
        </div>
        <div class="form-group">
          <label for="projectDescription">Project Description</label>
          <textarea class="form-control" id="projectDescription" v-model="newProject.description"></textarea>
        </div>
        <!-- Add additional project fields as required -->
        <button v-demo-mode class="btn btn-success">Submit</button>
        <p v-if="successMessage">{{ successMessage }}</p>
      </form>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OfficeProfile',
  data() {
    return {
      officeDetails: {},
      projects: [],
      showAddProjectForm: false,
      newProject: { name: '', description: '' }, // Initialize newProject object
      successMessage: '' 
    };
  },
  methods: {
    async fetchOfficeDetails() {
      try {
        const officeSlug = this.$route.params.slug; // Fetch office by slug
        const response = await axios.get(`/api/offices/slug/${officeSlug}`);
        this.officeDetails = response.data;
        console.log("Fetched details for office:", this.officeDetails.office_name);
      } catch (error) {
        console.error('Error fetching office details:', error);
      }
    },
    async fetchProjects() {
      try {
        console.log("Fetching projects for office:", this.officeDetails.office_name);
        const response = await axios.get(`/api/projects/offices/${this.officeDetails.slug}/projects`);
        console.log("Projects fetched:", response.data);
        this.projects = response.data;
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    },
   async toggleAddProjectForm() {
        console.log('Add Projects button clicked');
        this.showAddProjectForm = !this.showAddProjectForm;
        this.successMessage = ''; // Reset success message when toggling the form
      },
    async addProject(newProjectData) {
      try {
        const response = await axios.post(`/api/projects`, newProjectData);
        console.log('Project added:', response.data);
      } catch (error) {
        console.error('Error adding project:', error);
      }
    },
    async submitProject() {
      if (!this.newProject.name.trim()) {
      alert('Project name is required');
      return;
    }
     
      try {
        const projectData = {
          ...this.newProject,
          office_id: this.officeDetails.office_id
        };
        const response = await axios.post('/api/projects', projectData);
        this.projects.push(response.data); // Assuming response.data contains the new project
        this.successMessage = 'Project added successfully!';
        this.resetProjectForm();
      } catch (error) {
        console.error('Error adding project:', error);
        this.successMessage = ''; // Reset success message on error
      }
    },
    resetProjectForm() {
      this.newProject = { name: '', description: '' };
      // Optionally, hide the form
      this.showAddProjectForm = false;
    }
  },
    watch: {
      // Reactively fetch projects when office details change
      officeDetails(newVal) {
        if (newVal) {
          this.fetchProjects();
        }
      },
      showAddProjectForm(newVal) {
        console.log('showAddProjectForm changed:', newVal);
      }
  },
  mounted() {
    this.fetchOfficeDetails();
  }
};
</script>

<style scoped>
.banner-container {
  width: 800px; /* Set the fixed width for the banner container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add padding for spacing around the content */
}

.banner-container img {
  max-width: 100%; /* Ensure the banner image doesn't exceed its container width */
  height:auto;
}
.content-container {
  max-width: 100%; /* Ensure it doesn't exceed the width of the banner container */
  margin: 20px auto; /* Add margin between the title and the banner */
  padding: 0 20px; /* Optional padding for content */
  box-sizing: border-box; /* Ensure padding doesn't increase the width */
}
.office-logo {
  max-width: 40px; /* Adjust the max-width of the logo as needed */
  margin-right: 10px; /* Add margin to separate the logo from the office name */
  height:auto;
}

.back-link {
  color: #007bff; /* Blue color for the link */
  text-decoration: none; /* No underline */
  cursor: pointer; /* Cursor changes to pointer on hover */
  font-weight: 500; /* Slightly bold text */
}

.back-link::before {
  content: '← '; /* Unicode left arrow */
  font-size: 1.2em; /* Slightly larger arrow */
}

</style>