import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

export default {
  async login(username, password) {
    console.log('Attempting to log in with', { username, password }); // Debug log
    try {
      // Post to the login endpoint and store the token on success
      const response = await axios.post('/api/users/login', { username, password });
      console.log('Login successful, response data:', response.data); // Debug log
      localStorage.setItem('user-token', response.data.token); // Store the token
      return true; // Return true on successful login
    } catch (error) {
      console.error('Login error:', error); // Debug log
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      throw new Error('Login failed'); // Re-throwing the error to be handled by the caller
    }
  },

  logout() {
    console.log('Logging out'); // Debug log
    localStorage.removeItem('user-token'); // Remove the token
  },

  isTokenExpired() {
    const token = localStorage.getItem('user-token');
    if (!token) {
      return true; // No token means "expired"
    }

    try {
      const decoded = jwtDecode(token);
      console.log('Token found:', JSON.stringify(decoded));
      const now = Date.now() / 1000; // current time in seconds
      return decoded.exp < now;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true; // Assume expired on error
    }
  },

  // Function to retrieve the token from localStorage
  getToken() {
    return localStorage.getItem('user-token');
  },

  // Add a method to handle token expiry
  handleTokenExpiry() {
    if (this.isTokenExpired()) {
      // Token is expired
      this.logout(); // Clear the token
      // Redirect to login or implement token refresh logic
      // Example: window.location = '/login';
    }
  },

  isAuthenticated() {
    const token = localStorage.getItem('user-token');
    console.log('Token found in storage:', token);
  
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem('user-token'); // Clear expired token
          console.log('Token is expired');
          return false;
        }
        console.log('Decoded token:', decodedToken);
        if (decodedToken.userId) {
          console.log('User logged in:', decodedToken.userId);
          return true;
        } else {
          console.log('No user ID found in token');
          return false;
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('user-token'); // Clear invalid token
        return false;
      }
    }
  
    return false;
  },
  
  // Add other methods as needed, such as registration, etc.
  async register(userData) {
    try {
      await axios.post('/register', userData);
      return true; // Return true on successful registration
    } catch (error) {
      console.error('Registration error:', error.response.data);
      throw new Error('Registration failed');
    }
  },
  
};