<template>
    <div class="app-layout">
       <!-- Conditionally render the AppHeader based on the isMainPage computed property -->
      <AppHeader v-if="!isMainPage" :isLoggedIn="isLoggedIn" />
      <main class="content">
        <router-view />
      </main>
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppHeader from '@/components/AppHeader.vue';
 // import AppFooter from '@/components/AppFooter.vue';
  
  export default {
    name: 'AppLayout',
    components: {
      AppHeader,
     // AppFooter,
    },
    props: {
      isLoggedIn: {
        type: Boolean,
        default: false, // Provide a default value if needed
      },
    },
    computed: {
    isMainPage() {
      // Determine if the current route is the main page based on your router setup
      return this.$route.name === '/'; // Adjust 'main' to match your actual main page route name
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add styles for the common layout */
  .app-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1; /* Allow content to expand and fill available space */
    padding: 20px; /* Add padding as needed */
  }
  
  /* Add more styles for the layout as needed */
  </style>
  