<template>
  <div class="container main-container">
    <div class="custom-container">
    <h1>Welcome to the CTMS</h1>
    <p>Explore a demo of our application or sign up using the form below.</p>

    <!-- Sign Up button -->
    <button @click="toggleForm" class="btn btn-primary">{{ showForm ? 'Close Form' : 'Sign Up' }}</button>

    <!-- Demo button -->
    <router-link to="/home">
      <button class="btn btn-primary">Demo</button>
    </router-link>

    <!-- Form -->
    <form v-if="showForm" @submit.prevent="submitForm">
      <!-- Form fields -->
      <div class="form-group">
        <input id="name" v-model="formData.name" type="text" class="form-control" placeholder="Name" required>
        <input id="email" v-model="formData.email" type="text" class="form-control" placeholder="Email" required>
        <input id="message" v-model="formData.message" type="text" class="form-control"  placeholder="Message: 'My company/community would like to sign up for the CTMS'" required>
      </div>
      <!-- Add more form fields here -->

      <!-- Submit button -->
      <button type="submit" class="btn btn-success">Submit</button>
    </form>

    <!-- Success message -->
    <p v-if="successMessage">{{ successMessage }}</p>
 
  </div>
 </div>
</template>

<script>
import axios from 'axios'; // Import Axios library

export default {
  name: 'MainPage',
  data() {
    return {
      showForm: false,
      formData: {
        name: '',
        email: '',
        message: ''
        // Add more form fields here
      },
      successMessage: '', // Display a success message when the form is submitted successfully
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm; // Toggle the form's visibility
    },
    async submitForm() {
      try {
        // Determine the API endpoint
        const apiEndpoint = '/api/signup/'; // Update with your actual endpoint
        console.log('Submitting form to:', apiEndpoint, 'with data:', this.formData);

        // Direct axios post request
        const response = await axios.post(apiEndpoint, this.formData);
        // Handle success
        console.log('Form submitted successfully:', response.data);
        alert('Form submitted successfully');
        // Reset form
        this.formData = { name: '', email: '', message: '' };
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Error submitting form');
      }
    }
  },
};
</script>
  
  <style scoped>
  .custom-container {
  text-align: center;
  justify-content: center;
  padding: 50px;
  }
  .button-container {
    margin-top: 30px;
  }
  
  .btn {
    margin: 10px;
    padding: 10px 20px;
    font-size: 18px;
  }
  
  /* Additional styling as required */
  </style>
  