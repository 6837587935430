<template>
  <div class="container main-container">
    <div v-if="!isAuthenticated">
    <h2>Login</h2>
    <div class="row">
      <div class="col-md-6">
        <!-- Login Form -->
        <form @submit.prevent="login" class="login-form">
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" class="form-control" id="username" v-model="credentials.username" required>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" v-model="credentials.password" required>
          </div>
          <div class="form-footer">
            <button v-demo-mode class="btn btn-primary">Login</button>
            <router-link to="/register" class="account-link">Need an account?</router-link>
          </div>
        </form>
        <!-- Error Message -->
        <p v-if="showErrorMessage" class="text-danger mt-2">Login details incorrect. Please try again.</p>
      </div>
    </div>
  </div>
  <div v-else>
    <p>User is already logged in.</p>
  </div>
  </div>
</template>

<script>
import authService from '@/authService';

export default {
  name: 'LoginUser',
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      showErrorMessage: false,
      isAuthenticated: authService.isAuthenticated(),
    };
  },
  created() {
    if (this.isAuthenticated) {
      // Redirect or display message
      console.log("User already logged in");
      // Optional: Redirect to profile or another page
      // this.$router.push('/profile');
    }
  },
  methods: {
    async login() {
      console.log('Login method triggered');
      if (!this.credentials.username || !this.credentials.password) {
        // Display error message if login details are empty
        this.showErrorMessage = true;
        return;
      }

      this.showErrorMessage = false; // Reset error message
      
      try {
      const response = await authService.login(this.credentials.username, this.credentials.password);
      console.log('Login response:', response);

      if (response && response.token) {
        console.log('Login successful, redirecting to profile...');
        localStorage.setItem('user-token', response.token); // Set user-token in localStorage
        this.$router.push('/profile');
      } else {
        console.log('Login failed: no token received');
        this.showErrorMessage = true;
      }
    } catch (error) {
      console.error('Authentication error:', error);
      this.showErrorMessage = true;
    }
  }
  }
};
</script>

<style scoped>
.form-footer {
  display: flex;
  justify-content: space-between; /* Aligns button to left, link to right */
  align-items: center;
  padding-top: 15px; /* Adds some space above the button */
}

.account-link-container {
  margin-top: 10px; /* Adds some space above the link */
}

.account-link {
  white-space: nowrap;
  color: #007bff; /* Bootstrap primary color for consistency */
  text-decoration: underline; /* Underline for emphasis */
}
/* Add your styles here */
</style>