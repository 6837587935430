<template>
  <div class="container main-container">
   <div class="custom-container">
    <!-- Content -->
    <div class="content">
      <h1>Welcome to the CTMS</h1>
      <p>This system helps manage and track project treasuries.</p>

      <!-- Login, Register and View Offices Buttons -->
      <div class="container">
          <div class="row">
            <div class="col-md-6">
              <!-- "View Office" button -->
              <router-link to="/offices" class="btn btn-primary">View Offices</router-link>
            </div>
            <div class="col-md-6 text-right">
              <!-- "Login/Register" buttons -->
              <router-link to="/login" class="btn btn-info">Login</router-link>
              <router-link to="/register" class="btn btn-success">Register</router-link>
            </div>
          </div>
        </div>


      <!-- Add Office and Add Projects Buttons (Visible only if logged in) -->
      <div v-if="isLoggedIn">
        <router-link to="/add-office" class="btn btn-success">Add Office</router-link>
        <router-link to="/add-project" class="btn btn-success">Add Project</router-link>
      </div>

         <!-- Company Logo Container -->
    <!--      <div class="logo-container text-center">
          <img src="../assets/ctms-logo-2-blue.png" alt="CTMS Logo" class="company-logo">
        </div -->

    </div>
  </div>
  </div>
</template>

<script>

//import Particles from "particles.js";

export default {
  
  name: 'HomePage',
  data() {
    return {
      // This should be replaced with actual logic to check if the user is logged in
      isLoggedIn: false
    };
  },
  // You can add methods to handle user authentication status
  mounted() {
  },
}
</script>

<style scoped>
.custom-container {
  text-align: center;
  justify-content: center;
  padding: 50px;
  }
.homepage-container {
  display: flex;
  justify-content: center; /* Center align content horizontally */
  align-items: center; /* Center align content vertically */
  /* Add more specific styling as needed */
}
/* Add styling for your home page */
</style>
